// import { createBrowserRouter, RouterProvider,Route,Navigate,Router ,Routes} from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  createBrowserRouter,
  RouterProvider

} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { createCustomTheme } from "./theme";
import routes from "./routes";
import { useSelector } from "react-redux";
import "./i18n";
import Login from "pages/authentication/login";
import AppSelection from "pages/Apps/AppSelection/AppSelection";

const App = () => {
  const isLoggedIn = useSelector((state) => state.auth.auth);
  const router = createBrowserRouter(routes());
  const {
    settings
  } = useSettings();

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes
  });
  
  return <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <RTL>
        <CssBaseline />
        {/* <RouterProvider router={router} /> */}


        {/* <Router>
                <Routes>
                    <Route
                        path="/*"
                        element={isLoggedIn ? <Routers /> : <Navigate to="/login" />}
                    />
                     
                    <Route path="/login" element={<Login />} />
                </Routes>
            </Router> */}


        { isLoggedIn ?
          (<RouterProvider router={router} />
          ) : (
            <Router>
              <Routes>
                <Route path="/*" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/app-selection" element={<AppSelection/>}/>
              </Routes>

            </Router>

          )}

      </RTL>
    </ThemeProvider>
  </StyledEngineProvider>;
};

export default App;