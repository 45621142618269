import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { JWTAuthProvider } from "contexts/JWTAuth";
import SettingsProvider from "contexts/settingsContext";
import App from "./App";

// import './AppSelection.css';
import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "simplebar-react/dist/simplebar.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import { store } from './Store';
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import './index.css';
const queryClient = new QueryClient();
let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<React.StrictMode>
  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider>
        <JWTAuthProvider>
          <App />
        </JWTAuthProvider>
      </SettingsProvider>
    </LocalizationProvider> */}
  <Suspense fallback={<div />}>
    <Provider store={store}>
      {/* <PersistGate persistor={persistor}> */}
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>

            {/* <App /> */}
            <SettingsProvider>
              <JWTAuthProvider>
                <App />
              </JWTAuthProvider>
            </SettingsProvider>
          </LocalizationProvider>

        </QueryClientProvider>
      {/* </PersistGate> */}
    </Provider>
  </Suspense>
</React.StrictMode>);
