import { Button, styled, Box, Tooltip, IconButton, tooltipClasses, Drawer } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import SearchInput from "components/input-fields/SearchInput";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { useTranslation } from "react-i18next";
import Filter from "components/Filter";
import { useState } from "react";

const StyledFlexBox = styled(FlexBetween)(({ theme }) => ({
  flexWrap: "wrap",
  marginBottom: 20,
  marginTop: 15,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "100%",
    },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    color: theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    padding: '8px 10px',
    fontSize: '15px',
  },
}));// -----------------------------------------------------------

// -----------------------------------------------------------
const ListHeader = ({
  setSearchValue,
  buttonText = "Create New",
  buttonText2 = "Upload Product",
  buttonText3 = "Upload Global catalog",
  handleClick,
  handleClick2,
  handleClick3,
  showButton = true,
  showButton2=false,
  showButton3=false,
  searchValue,
  placeholder,
}) => {
  const { t } = useTranslation();

  const [showFilterPanel, setShowFilterPanel] = useState(false);

  const toggleFilterPanel = () => {
    setShowFilterPanel(!showFilterPanel);
  };

  return (
    <StyledFlexBox>
      <SearchInput
        bordered={true}
        value={searchValue?searchValue:""}
        placeholder={placeholder}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <Box>
        {showButton && (
          <Button variant="contained" onClick={handleClick} sx={{ marginRight: '20px' }}>
            {t(buttonText)}
          </Button>
        )}
         {showButton2 && (
          <Button variant="contained" onClick={handleClick2} sx={{ marginRight: '20px' }}>
            {t(buttonText2)}
          </Button>
        )}
         {showButton3 && (
          <Button variant="contained" onClick={handleClick3} sx={{ marginRight: '20px' }}>
            {t(buttonText3)}
          </Button>
        )}
        {/* <BootstrapTooltip title="Filter" placement="top" arrow>
          <IconButton onClick={toggleFilterPanel}>
            <TuneOutlinedIcon sx={{ fontSize: '33px' }} />
          </IconButton>
        </BootstrapTooltip> */}
      </Box>

      <Drawer
        anchor="right"
        open={showFilterPanel}
        onClose={toggleFilterPanel}
        transitionDuration={{ enter: 600, exit: 400 }}
      >
        <Filter closefilter={toggleFilterPanel} />
      </Drawer>
    </StyledFlexBox>
  );
};

export default ListHeader;
