import { createSlice } from "@reduxjs/toolkit";
import { tr } from "date-fns/locale";



const initialState={
 selectedapp:null
}

export const Appslice=createSlice({
    name:"appslice",
    initialState,
    reducers:{
        setAppdata:(state,action)=>{
         //   console.log("stateapp",action)
          state.selectedapp = action.payload.appdata;
         

        },
        clearAppdata:(state,action)=>{
            state.selectedapp = null;
         
        }
      
    }

})

export const { setAppdata,clearAppdata } = Appslice.actions;
export const getAppID = state => state.appslice.selectedapp;
export default Appslice.reducer;
