import React from 'react';
import { Box, TextField, Typography, Button } from "@mui/material";
import AppTextField from "./input-fields/AppTextField";

const Filter = ({ closefilter }) => {
    return (
        <Box pt={2} pb={4} px={3} width={400}>
            <Typography variant="h6" mb={2}>Filter Options</Typography>
            <AppTextField
                size="small"
                label="Product Name"
                fullWidth
                name="productName"
            />
            <TextField
                label="Filter 2"
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Filter 3"
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <TextField
                label="Filter 4"
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="outlined" color="primary" onClick={closefilter}>Cancel</Button>
                <Button variant="contained" color="primary">Search</Button>
            </Box>
        </Box>
    );
};

export default Filter;
