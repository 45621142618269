import { createSlice } from "@reduxjs/toolkit";
import { tr } from "date-fns/locale";



const initialState={
  auth: false,
  user: null,
  token: null,
}

export const Authslice=createSlice({
    name:"auth",
    initialState,
    reducers:{
        login:(state,action)=>{
        //  console.log("stateauth",action)
          state.auth = true;
          state.user=action.payload.user;
          state.token = action.payload.token;

        },
        logout:(state,action)=>{
          state.auth = false;
          state.user=null;
          state.token=null;
        }
    }

})

export const { login, logout, setAppid, setTabValue } = Authslice.actions;
export const getuserdata = state => state.auth.user;
export default Authslice.reducer;
