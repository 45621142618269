import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Snackbar,
  Box,
  Stack,
  Card,
  Modal,
} from "@mui/material";
import { Edit, Delete, Add, Category } from "@mui/icons-material";
import { Alert } from "@mui/lab";
import styled from "@emotion/styled";
import IconWrapper from "components/IconWrapper";
import { H5 } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import { useTranslation } from "react-i18next";
import SearchInput from "components/input-fields/SearchInput";
import CustomTable from "page-sections/admin-ecommerce/CustomTable";
import { Space } from "antd";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
// http://localhost:5001/
// const API_BASE_URL = 'https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1';
const API_BASE_URL = "https://backend.booksmart.co.in/api/";

const CategoryList = () => {
  const selectedApp = useSelector((state) => state.appslice.selectedapp);
  const selectedmerchant = useSelector(
    (state) => state.merchantslice.selectedmerchant
  );
  const appId = selectedApp?.AppID || "";
  const merchantId = selectedmerchant?._id || "";
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [validationError, setValidationError] = useState(false); // Track validation errors
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const userToken = useSelector((state) => state.auth.token);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}category/list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setCategories(response.data.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error fetching categories",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddCategory = async () => {
    if (currentCategory.trim() === "") {
      setValidationError(true);
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        `${API_BASE_URL}category/create`,
        {
          categoryName: currentCategory,
          categoryImage: "",
          categoryBannerImage: [],
          isActive: true,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      fetchCategories();
      toast.success("Category added successfully");
      // setSnackbar({
      //   open: true,
      //   message: "Category added successfully",
      //   severity: "success",
      // });
      setCurrentCategory("");
      setIsModalOpen(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error adding category",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditCategory = async () => {
    if (currentCategory.trim() === "") {
      setValidationError(true);
      return;
    }

    setLoading(true);
    try {
      await axios.put(
        `${API_BASE_URL}category/update`,
        {
          categoryName: currentCategory,
          _id: editCategoryId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const updatedCategories = categories.map((category) =>
        category.id === editCategoryId
          ? { ...category, CategoryName: currentCategory }
          : category
      );
      // setCategories(updatedCategories);
      fetchCategories();
      setSnackbar({
        open: true,
        message: "Category updated successfully",
        severity: "success",
      });
      setEditCategoryId(null);
      setCurrentCategory("");
      setIsModalOpen(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error updating category",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrEdit = () => {
    setValidationError(false); // Reset validation error state
    if (editCategoryId !== null) {
      handleEditCategory();
    } else {
      handleAddCategory();
    }
  };

  const handleEdit = (category) => {
    setCurrentCategory(category.categoryName);
    setEditCategoryId(category._id);
    setIsModalOpen(true);
  };
  const handleDelete = async (id) => {
    console.log(id)
    setLoading(true);
    try {
      await axios.post(
        `${API_BASE_URL}category/delete`,
        {
          _id: id,
          isActive: false,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      fetchCategories(); // Fetch updated list
     // setSnackbar({
      //   open: true,
      //   message: "Category deleted successfully",
      //   severity: "success",
      // });
      toast.success("Category deleted successfully");
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error deleting category",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setOpenDeleteConfirm(false);
    }
  };
  // Open Delete Confirmation
  const handleOpenDeleteConfirm = (Id) => {
    setCategoryToDelete(Id);
    setOpenDeleteConfirm(true);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentCategory("");
    setEditCategoryId(null);
    setValidationError(false); // Reset validation error on close
  };

  const column = [
    {
      Header: "Category Name",
      accessor: "categoryName",
    },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ row }) => (
        <Space>
          <IconButton onClick={() => handleEdit(row.original)}>
            <Edit sx={{ color: "text.disabled", fontSize: 18 }} />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteConfirm(row.original._id)}>
            <Delete sx={{ color: "text.disabled", fontSize: 18 }} />
          </IconButton>
        </Space>
      ),
    },
  ];

  const StyledStack = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down(565)]: {
      width: "100%",
    },
  }));

  const categoriesData = categories.filter((val) =>
    val.categoryName.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Box pt={2} pb={4}>
      <Card sx={{ py: 2 }}>
        <FlexBetween flexWrap="wrap" gap={1} mb={2} px={3}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <Category sx={{ color: "primary.main" }} />
            </IconWrapper>
            <H5>{t("Category")}</H5>
          </Stack>
          <StyledStack alignItems="center" direction="row" gap={2}>
            <SearchInput
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              bordered={true}
              placeholder="Type Category Name to Search"
            />
            <Button
              onClick={handleOpenModal}
              variant="contained"
              startIcon={<Add />}
              sx={{ flexShrink: 0 }}
            >
              Add New
            </Button>
          </StyledStack>
        </FlexBetween>
        <Box px={3}>
          <CustomTable columnShape={column} data={categoriesData} />

          {loading && <CircularProgress />}
        </Box>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box sx={modalStyle}>
            <H5 id="modal-title">
              {editCategoryId !== null ? "Edit Category" : "Add Category"}
            </H5>
            <TextField
              autoFocus
              margin="dense"
              label="Category Name"
              type="text"
              fullWidth
              size="small"
              // variant="standard"
              value={currentCategory}
              onChange={(e) => setCurrentCategory(e.target.value)}
              error={validationError} // Set error to true if validation fails
              helperText={validationError ? "Category name is required" : ""} // Show helper text in red
            />

            <Box
              sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
            >
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                sx={{ marginRight: 1 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddOrEdit}
                variant="contained"
                disabled={loading}
              >
                {editCategoryId !== null ? "Update" : "Add"}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
        >
          <Box sx={modalStyle}>
            <H5>Confirm Deletion</H5>
            <p>Are you sure you want to delete this Category?</p>
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              <Button onClick={() => setOpenDeleteConfirm(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDelete(categoryToDelete)}
              >
                Delete
              </Button>
            </Stack>
          </Box>
        </Modal>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Card>
    </Box>
  );
};

export default CategoryList;
