import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import { Authslice } from "./Slices/Authslice";
import { Appslice } from "./Slices/Appslice";
import { Merchantslice } from "./Slices/Merchantslice";
const reducers = combineReducers({
    auth: Authslice.reducer,
    appslice:Appslice.reducer,
    merchantslice:Merchantslice.reducer,
});

const persistConfig = {
    key: "root",
    storage,
};
// console.log(persistConfig)


const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

});