import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { H1, Paragraph } from "components/Typography";
import React from "react";
import { lightTheme } from "../../constants";

// Styled component for centering the content
const CenteredContainer = styled(Box)(({ theme }) => ({
  height: "100vh", // Full viewport height
  display: "flex",
  justifyContent: "center", // Horizontally center
  alignItems: "center", // Vertically center
  backgroundColor: "#ffffff"
}));

const ContentSlider = () => {
  return (
    <CenteredContainer>
      <SlideComponent
        img="/static/illustration/logo.png"
      />
    </CenteredContainer>
  );
};

export default ContentSlider;

function SlideComponent({ img, title, descripiton }) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <img alt="slide" src={img} style={{ maxWidth: 400, maxHeight: "80vh" }} />
      <H1 fontWeight={700} mt={3}>
        {title}
      </H1>
      <Paragraph>{descripiton}</Paragraph>
    </Box>
  );
}
