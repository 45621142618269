import { createSlice } from "@reduxjs/toolkit";
import { tr } from "date-fns/locale";



const initialState={
 selectedmerchant:null
}

export const Merchantslice=createSlice({
    name:"merchantslice",
    initialState,
    reducers:{
        setMerchantdata:(state,action)=>{
         //   console.log("stateapp",action)
          state.selectedmerchant = action.payload.merchantdata;
         

        },
        clearMerchantdata:(state,action)=>{
            state.selectedmerchant = null;
         
        }
      
    }

})

export const { setMerchantdata,clearMerchantdata } = Merchantslice.actions;

export default Merchantslice.reducer;
