import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  IconButton,
  Modal,
  CircularProgress,
  Snackbar,
  Box,
  Stack,
  Card,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import toast from "react-hot-toast";
import {
  Edit,
  Delete,
  Add,
  SubdirectoryArrowRightSharp,
} from "@mui/icons-material";
import { Alert } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import { H5 } from "components/Typography";
import styled from "@emotion/styled";
import SearchInput from "components/input-fields/SearchInput";
import CustomTable from "page-sections/admin-ecommerce/CustomTable";
import { Space } from "antd";
import { useSelector } from "react-redux";

const API_BASE_URL = "https://backend.booksmart.co.in/api/";

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%",
  },
}));

const SubcategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [currentSubcategory, setCurrentSubcategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [editSubcategoryById, setEditSubcategoryById] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [subcategoryToDelete, setSubategoryToDelete] = useState(null);
  const [categoryError, setCategoryError] = useState(false);
  const [subcategoryError, setSubcategoryError] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const userToken = useSelector((state) => state.auth.token);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const handleEdit = (data) => {
    setSubcategoryid(data._id);
    const categoryById = categories.find(
      (item) => item.categoryName === data.categoryName
    );
    setSelectedCategory(categoryById?._id || "");
    setCurrentSubcategory(data.subCategoryName);
    setEditSubcategoryById(data._id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentSubcategory("");
    setSelectedCategory("");
    setEditSubcategoryById(null);
    setCategoryError(false);
    setSubcategoryError(false);
  };

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}category/list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setCategories(response.data.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error fetching categories",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchSubcategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}subcategory/list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setSubcategories(response.data.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error fetching subcategories",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddOrEdit = () => {
    if (!validateInputs()) return;

    if (editSubcategoryById !== null) {
      handleEditSubcategory();
    } else {
      handleAddSubcategory();
    }
  };

  const validateInputs = () => {
    let valid = true;

    if (!selectedCategory) {
      setCategoryError(true);
      valid = false;
    } else {
      setCategoryError(false);
    }

    if (!currentSubcategory.trim()) {
      setSubcategoryError(true);
      valid = false;
    } else {
      setSubcategoryError(false);
    }

    return valid;
  };

  const handleAddSubcategory = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${API_BASE_URL}subcategory/create`,
        {
          subCategoryName: currentSubcategory,
          categoryName: categoryName,
          categoryById: selectedCategory,
          isActive: true,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      fetchSubcategories();
      // setSnackbar({
      //   open: true,
      //   message: "Subcategory added successfully",
      //   severity: "success",
      // });
      
      toast.success("Subcategory added successfully");
      handleCloseModal();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error adding subcategory",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubcategory = async () => {
    setLoading(true);
    try {
      await axios.put(
        `${API_BASE_URL}subcategory/update`,
        {
          subCategoryName: currentSubcategory,
          _id: subcategoryid,
          categoryName: categoryName,
          categoryById: selectedCategory
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      fetchSubcategories();
      // setSnackbar({
      //   open: true,
      //   message: "Subcategory updated successfully",
      //   severity: "success",
      // });
      toast.success("Subcategory updated successfully");
      handleCloseModal();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error updating subcategory",
        severity: "error",
      });

    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirm = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${API_BASE_URL}subcategory/delete`, {
        isActive: false,
        _id: id
      },  {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      fetchSubcategories();
      // setSnackbar({
      //   open: true,
      //   message: "Subcategory deleted successfully",
      //   severity: "success",
      // });
      toast.success("Subcategory deleted successfully");
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error deleting subcategory",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setOpenDeleteConfirm(false);
    }
  };

  const handleOpenDeleteConfirm = (Id) => {
    setSubategoryToDelete(Id);
    setOpenDeleteConfirm(true);
  };

  const column = [
    {
      Header: "Sub Category",
      accessor: "subCategoryName",
    },
    {
      Header: "Category",
      accessor: "categoryName",
    },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ row }) => (
        <Space>
          <IconButton onClick={() => handleEdit(row.original)}>
            <Edit sx={{ color: "text.disabled", fontSize: 18 }} />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteConfirm(row.original._id)}>
            <Delete sx={{ color: "text.disabled", fontSize: 18 }} />
          </IconButton>
        </Space>
      ),
    },
  ];

  const subcategoriesData = subcategories.filter((val) =>
    val.subCategoryName.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Box pt={2} pb={4}>
      <Card sx={{ py: 2 }}>
        <FlexBetween flexWrap="wrap" gap={1} mb={2} px={3}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <SubdirectoryArrowRightSharp sx={{ color: "primary.main" }} />
            </IconWrapper>
            <H5>{"Sub Category"}</H5>
          </Stack>
          <StyledStack alignItems="center" direction="row" gap={2}>
            <SearchInput
              placeholder="Type SubCategory Name to Search"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
            <Button
              onClick={() => setIsModalOpen(true)}
              variant="contained"
              startIcon={<Add />}
              sx={{ flexShrink: 0 }}
            >
              Add New
            </Button>
          </StyledStack>
        </FlexBetween>
        <Box px={3}>
          <CustomTable columnShape={column} data={subcategoriesData} />

          {loading && <CircularProgress />}
        </Box>
      </Card>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <H5>
            {editSubcategoryById ? "Edit Sub Category" : "Add Sub Category"}
          </H5>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }} error={categoryError}>
            <InputLabel>Select Category</InputLabel>
            <Select
              value={selectedCategory}
              autoFocus={true}
              label="Select Category"
              size="small"
              onChange={(e) => {
                const categoryname = categories.find(
                  (item) => item._id === e.target.value
                );
                setCategoryName(categoryname.categoryName);                
                setSelectedCategory(e.target.value);
              }}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.categoryName}
                </MenuItem>
              ))}
            </Select>
            {categoryError && (
              <FormHelperText>Category is required</FormHelperText>
            )}
          </FormControl>
          <TextField
            label="Sub Category Name"
            autoFocus={true}
            value={currentSubcategory}
            onChange={(e) => setCurrentSubcategory(e.target.value)}
            fullWidth
            size="small"
            sx={{ mb: 2 }}
            error={subcategoryError}
            helperText={subcategoryError ? "Sub Category Name is required" : ""}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddOrEdit}
              variant="contained"
              color="primary"
            >
              {editSubcategoryById ? "Update" : "Add"}
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Modal
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
      >
        <Box sx={modalStyle}>
          <H5>Confirm Deletion</H5>
          <p>Are you sure you want to delete this SubCategory?</p>
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button onClick={() => setOpenDeleteConfirm(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteConfirm(subcategoryToDelete)}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SubcategoryList;
