import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Modal,
  Box,
  Stack,
  Card,
  IconButton,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { Edit, Delete, BrandingWatermark, Add } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import FlexBetween from 'components/flexbox/FlexBetween';
import IconWrapper from 'components/IconWrapper';
import { H5 } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import SearchInput from 'components/input-fields/SearchInput';
import { Space } from 'antd';
import CustomTable from 'page-sections/admin-ecommerce/CustomTable';

const API_BASE_URL = 'https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1';

const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(565)]: {
    width: '100%',
  },
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const BrandList = () => {
  const [brands, setBrands] = useState([]);
  const [currentBrand, setCurrentBrand] = useState('');
  const [editBrandId, setEditBrandId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false); // State for handling errors
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  // Fetch brands from API
  const fetchBrands = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/brand`);
      setBrands(response.data.brand);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching brands',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);
  const handleOpenDeleteConfirm = (brandId) => {
    setBrandToDelete(brandId);
    setOpenDeleteConfirm(true);
  };

  // Add or Edit Brand
  const handleAddOrEdit = async () => {
    if (currentBrand.trim() === '') {
      setError(true); // Set error if input is empty
      return;
    }

    setLoading(true);
    setError(false); // Reset error when proceeding with valid input

    try {
      if (editBrandId) {
        await axios.put(`${API_BASE_URL}/brand/${editBrandId}`, { BrandName: currentBrand });
        setBrands(
          brands.map((brand) =>
            brand._id === editBrandId ? { ...brand, BrandName: currentBrand } : brand
          )
        );
        setSnackbar({
          open: true,
          message: 'Brand updated successfully',
          severity: 'success',
        });
      } else {
        await axios.post(`${API_BASE_URL}/brand`, { BrandName: currentBrand });
        fetchBrands();
        setSnackbar({
          open: true,
          message: 'Brand added successfully',
          severity: 'success',
        });
      }
      setOpenModal(false);
      setCurrentBrand('');
      setEditBrandId(null);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error saving brand',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // Delete Brand
  const handleDeleteBrand = async () => {
    if (!brandToDelete) return;

    setLoading(true);
    try {
      await axios.delete(`${API_BASE_URL}/brand/${brandToDelete}`);
      fetchBrands();
      setSnackbar({
        open: true,
        message: 'Brand deleted successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error deleting brand',
        severity: 'error',
      });
    } finally {
      setLoading(false);
      setOpenDeleteConfirm(false);
      setBrandToDelete(null);
    }
  };

  // Open Add/Edit Modal
  const handleOpenModal = (brand = null) => {
    if (brand) {
      setCurrentBrand(brand.BrandName);
      setEditBrandId(brand._id);
    } else {
      setCurrentBrand('');
      setEditBrandId(null);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentBrand('');
    setEditBrandId(null);
  };

  const columns = [
    {
      Header: 'Brand Name',
      accessor: 'BrandName',
    },
    {
      Header: 'Action',
      accessor: 'actions',
      Cell: ({ row }) => (
        <Space>
          <IconButton onClick={() => handleOpenModal(row.original)}>
            <Edit sx={{ color: 'text.disabled', fontSize: 18 }} />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteConfirm(row.original._id)}>
            <Delete sx={{ color: 'text.disabled', fontSize: 18 }} />
          </IconButton>
        </Space>
      ),
    },
  ];
  const brandsData = brands.filter((val) =>
    val.BrandName.toLowerCase().includes(searchValue.toLowerCase())
);

  return (
    <Box pt={2} pb={4}>
      <Card sx={{ py: 2 }}>

        {/* <FlexBetween flexWrap="wrap" gap={1} mb={2} px={3}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <BrandingWatermark sx={{ color: 'primary.main' }} />
            </IconWrapper>
            <H5>{t('Brand')}</H5>
          </Stack>

          <StyledStack alignItems="center" direction="row" gap={2}>
            <SearchInput bordered={true} placeholder="Type Brand Name to Search" />
            <Button onClick={() => handleOpenModal()} variant="contained" startIcon={<Add />}>
              Add New
            </Button>
          </StyledStack>
        </FlexBetween> */}
          <FlexBetween flexWrap="wrap" gap={1} mb={2} px={3}>
          <Stack direction="row" alignItems="center">
            <IconWrapper>
              <BrandingWatermark sx={{ color: 'primary.main' }} />
            </IconWrapper>
            <H5>{t('Brand')}</H5>
          </Stack>
          <StyledStack alignItems="center" direction="row" gap={2}>
            <SearchInput bordered={true} placeholder="Type Brand Name to Search"
             onChange={e => setSearchValue(e.target.value)}
             value={searchValue}
             />
            <Button
              onClick={handleOpenModal}
              variant="contained"
              startIcon={<Add />}
              sx={{ flexShrink: 0 }}
            >
              Add New
            </Button>
          </StyledStack>
        </FlexBetween>
        <Box px={3}>
          <CustomTable data={brandsData} columnShape={columns} />


          {loading && <CircularProgress />}
        </Box>

        {/* Add/Edit Modal */}
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={modalStyle}>
            <H5>{editBrandId ? 'Edit Brand' : 'Add Brand'}</H5>
            <TextField
              fullWidth
              size="small"
              label="Brand Name"
              autoFocus
              value={currentBrand}
              onChange={(e) => setCurrentBrand(e.target.value)}
              sx={{ mt: 2, mb: 2 }}
              error={error} // Set error prop
              helperText={error ? 'Brand Name is required' : ''} // Display error message
              InputProps={{
                style: error ? { borderColor: 'red' } : {}, // Apply red border on error
              }}
            />
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button variant="contained" onClick={handleAddOrEdit} disabled={loading}>
                {editBrandId ? 'Update' : 'Add'}
              </Button>
            </Stack>
          </Box>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)}>
          <Box sx={modalStyle}>
            <H5>Confirm Deletion</H5>
            <p>Are you sure you want to delete this brand?</p>
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              <Button onClick={() => setOpenDeleteConfirm(false)}>Cancel</Button>
              <Button variant="contained" color="error" onClick={handleDeleteBrand}>
                Delete
              </Button>
            </Stack>
          </Box>
        </Modal>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Card>
    </Box>
  );
};

export default BrandList;
