import React, { useEffect, useState } from 'react';
import { Space, Table, Typography } from 'antd';
import axios from 'axios';
import { Button, Stack, Box, Card, IconButton ,Snackbar,Modal} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ListHeader from '../ListHeader';
import { styled } from "@mui/system";
import FlexBetween from "components/flexbox/FlexBetween";
import IconWrapper from "components/IconWrapper";
import SearchInput from "components/input-fields/SearchInput";
import { H5 } from "components/Typography";
import Add from "icons/Add";
import Invoice from "icons/sidebar/Invoice";
import { useTranslation } from "react-i18next";
import CustomTable from 'page-sections/admin-ecommerce/CustomTable';
import { Delete, Edit } from '@mui/icons-material';
import User from 'icons/User';
import { Alert } from '@mui/lab';
const StyledStack = styled(Stack)(({
  theme
}) => ({
  [theme.breakpoints.down(565)]: {
    width: "100%"
  }
}));
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


const UserList = () => {
  const [userData, setUserData] = useState([]);
  const [appData, setAppData] = useState([]);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const {
    t
  } = useTranslation();
  // Fetch users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/user');
        setUserData(response.data.users);
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };

    fetchData();
  }, []);

  // Fetch app data
  useEffect(() => {
    const fetchAppData = async () => {
      try {
        const response = await axios.get('https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/app');
        setAppData(response.data.apps);
      } catch (error) {
        console.error('Error fetching app data:', error);
      }
    };

    fetchAppData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/user/${id}`);
      if (response.status === 200) {
        // alert('User Deleted Successfully');
        setSnackbar({
          open: true,
          message: "User deleted successfully",
          severity: 'success',
        });
        // Refresh user list after deletion
        setUserData(userData.filter(user => user._id !== id));
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting User',
        severity: 'error',
      });
    }finally {
      //setLoading(false);
      setOpenDeleteConfirm(false);
      setBrandToDelete(null);
    }
  };

  const handleOpenDeleteConfirm = (Id) => {
    setBrandToDelete(Id);
    setOpenDeleteConfirm(true);
  };
  // const handleCloseModal = () => {
  //   setOpenModal(false);
  //   setBrandToDelete('');
  //   setEditBrandId(null);
  // };

  const handleEdit = (user) => {
    navigate('/dashboard/add-user', { state: { user } });
  };

  // Define columns for the table
  const columns = [
    {

      Header: "User Name",
      accessor: "Name",

    },
    {
      Header: "Login ID",
      accessor: "LoginId"
    },
    {
      Header: "App ID",
      accessor: "AppId",
      Cell: ({ row }) => {
        const AppId = row.original.AppId
        // console.log(appId)
        // console.log(appData)
        return AppId.map(appId => {
          const app = appData.find(app => app.AppID === appId);
          return app ? app.AppName : 'Unknown App';
        }).join(', ');
        // console.log(appName)
        //       return appName ? appName.AppName : 'Unknown App';
        //   let divisions = "";
        //   if (standard.Divisions && Array.isArray(standard.Divisions)) {
        //     divisions = standard.Divisions.join(', ');
        //   }
        //   return ⁠ ${standard.Standard}: ${divisions} ⁠;
        // }).join(' | ');
        // return <span>{standards}</span>;
      }
      // cell: (AppId) => {
      //   // Assuming appIds is an array of App IDs
      //   return AppId
      //     .map(appId => {
      //       const app = appData.find(app => app.AppID === appId);
      //       return app ? app.AppName : 'Unknown App';
      //     })
      //     .join(', ');
      // },
    },
    {
      Header: "Role",
      accessor: "Role"
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        // console.log(record),
       // const AppId = record.original._id
        <Space>
          {/* <Button onClick={() => handleEdit(record)} variant="contained" color="primary">Edit</Button> */}
          {/* <IconButton onClick={() => handleDelete(row.original._id)}>
            <Delete sx={{
              color: "text.disabled",
              fontSize: 18
            }} />
          </IconButton> */}
           <IconButton onClick={() => handleOpenDeleteConfirm(row.original._id)}>
            <Delete sx={{ color: 'text.disabled', fontSize: 18 }} />
          </IconButton>
          <IconButton onClick={() => {
            handleEdit(row.original)
          }}>
            <Edit sx={{
              color: "text.disabled",
              fontSize: 18
            }} />
          </IconButton>
          {/* <Button onClick={() => handleDelete(record._id)} variant="contained" color="error">Delete</Button> */}
        </Space>
      ),
    },
  ];
  const datauser = userData.filter((val) =>
    val.Name.toLowerCase().includes(searchValue.toLowerCase())
);

  return (
    <Box pt={2} pb={4}>
      <Card sx={{
        py: 2
      }}>
       
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Typography.Title level={2}>User Details</Typography.Title>
        <Button onClick={() => navigate('/dashboard/add-user')} variant='contained' color='primary'>Add User</Button>
      </div> */}
          <FlexBetween flexWrap="wrap" gap={1} mb={2} px={3}>
            <Stack direction="row" alignItems="center">
              <IconWrapper>
                <User sx={{
                  color: "primary.main"
                }} />
              </IconWrapper>
              <H5>{t("User Management")}</H5>
            </Stack>

            <StyledStack alignItems="center" direction="row" gap={2}>
              <SearchInput bordered={true} placeholder="Type User Name to Search"
                  onChange={e => setSearchValue(e.target.value)}
                  value={searchValue}
               />
              <Button onClick={() => navigate('/dashboard/add-user')} variant="contained" startIcon={<Add />} sx={{
                flexShrink: 0
              }}>
                Add New
              </Button>
            </StyledStack>
          </FlexBetween>

        <Box px={3}>
          <CustomTable columnShape={columns} data={datauser} />
        </Box>

         {/* Delete Confirmation Modal */}
         <Modal open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)}>
          <Box sx={modalStyle}>
            <H5>Confirm Deletion</H5>
            <p>Are you sure you want to delete this User?</p>
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              <Button onClick={() => setOpenDeleteConfirm(false)}>Cancel</Button>
              <Button variant="contained" color="error" onClick={()=>handleDelete(brandToDelete)}>
                Delete
              </Button>
            </Stack>
          </Box>
        </Modal>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Card>

    </Box>
  );
};

export default UserList;