import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Container, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Card } from '@mui/material';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import FlexBox from 'components/flexbox/FlexBox';
import FlexBetween from 'components/flexbox/FlexBetween';
import FlexRowAlign from 'components/flexbox/FlexRowAlign';
import { H5, H6, Small } from 'components/Typography';
import IconWrapper from 'components/IconWrapper';
import User from 'icons/User';
import { useSelector } from 'react-redux'
import toast, { Toaster } from "react-hot-toast";
const AddUserPage = () => {
  const location = useLocation();
  const userData = location?.state?.user || null;
  const isLoggedIn = useSelector((state) => state.auth.user);
  const selectedApp = useSelector((state) => state.appslice.selectedapp);
  const [formData, setFormData] = useState({
    LoginId: userData?.LoginId || '',
    Password: userData?.Password || '',
    ConfirmPassword: userData?.Password || "",
    Role: userData?.Role || '',
    AppId: userData?.AppId || [], // Initialize as an array
    Name: userData?.Name || '',
  });

  const [appData, setAppData] = useState([]);
  const [formErrors, setFormErrors] = useState({}); // New state to track errors
  const [merchantData, setMerchantData] = useState([]);
  const [merchant, setMerchant] = useState("");
  // Fetch app data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        // 'https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/app'
         ` https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/app/${selectedApp?.AppID}`
      );
      setAppData([response.data.app]);
    } catch (error) {
      console.error('Error fetching app data:', error);
    }
  };
  const getMerchantData = async () => {
    try {
      
      if (isLoggedIn?.Role !== "System Admin") {
        const response = await axios.get(
          // `https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/merchant?"appid"=${selectedApp?.AppId}`,
          "https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/merchant",
        );
        const allappMerchantdata = response.data.merchants;
        setMerchantData(allappMerchantdata);
      } else {
        const response = await axios.get(
          "https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/merchant",
        );
        const allappMerchantdata = response.data.merchants;
        setMerchantData(allappMerchantdata);
      }
    } catch (error) {
      console.error("Error geting merchant data:", error);
    }
  }

  useEffect(() => {
    fetchData();
    getMerchantData()
  }, []);
  // console.log("merchantData",merchantData)

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'AppId') {
      setFormData({ ...formData, AppId: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Form validation function
  const validateForm = () => {
    let errors = {};

    if (!formData.Name) errors.Name = 'Name is required';
    if (!formData.LoginId) errors.LoginId = 'Login ID is required';
    if (!formData.Password) errors.Password = 'Password is required';
    if (!userData && formData.Password !== formData.ConfirmPassword) errors.ConfirmPassword = 'Passwords do not match';
    if (!formData.Role) errors.Role = 'Role is required';
    if(formData.Role=="Merchnat Admin"&&!merchant){
      errors.Role = 'Merchnat is required';
    }
    // if (!formData.Role) errors.Role = 'Role is required';
    if (formData.AppId.length === 0) errors.AppId = 'At least one App ID is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const endpoint = userData
        ? `https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/user/${userData._id}`
        : 'https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/user';

      const response = userData
        ? await axios.put(endpoint, formData)
        : await axios.post(endpoint, formData);
      toast.success(response.data.message);
      // alert(response.data.message);

      // Clear form or redirect as needed
      {
        !userData && setFormData({
          LoginId: '',
          Password: '',
          ConfirmPassword: '',
          Role: '',
          AppId: [], // Reset to an empty array
          Name: '',
        });
      }
      setFormErrors({}); // Clear errors on successful submission
    } catch (error) {
      console.error('Error processing request:', error);
      alert('Error processing request');
    }
  };

  return (
    <Box pt={2} pb={4}>
      <Toaster position="top-center" />

      <Grid container spacing={3}>
        <Grid item xs={12} mb={2}>
          <FlexBox alignItems="center">
            <IconWrapper>
              <User sx={{ color: 'primary.main' }} />
            </IconWrapper>
            <H5> {userData ? 'Update User' : 'Add User'}</H5>
          </FlexBox>

        </Grid>
        <Grid item sm={12} xs={12} md={6} mb={2}>
          <Card sx={{ padding: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="Name"
                    size="small"
                    value={formData.Name}
                    onChange={handleChange}
                    // required
                    error={!!formErrors.Name} // Apply red border if there's an error
                    helperText={formErrors.Name} // Display error message
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Login ID"
                    name="LoginId"
                    size="small"
                    value={formData.LoginId}
                    onChange={handleChange}
                    // required
                    error={!!formErrors.LoginId}
                    helperText={formErrors.LoginId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    label="Password"
                    name="Password"
                    size="small"
                    value={formData.Password}
                    onChange={handleChange}
                    // required
                    error={!!formErrors.Password}
                    helperText={formErrors.Password}
                  />
                </Grid>
                {!userData && <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    size="small"
                    label="Confirm Password"
                    name="ConfirmPassword"
                    value={formData.ConfirmPassword}
                    onChange={handleChange}
                    // required={!userData}
                    error={!!formErrors.ConfirmPassword}
                    helperText={formErrors.ConfirmPassword}
                  />
                </Grid>}
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!formErrors.Role}>
                    <InputLabel>Role</InputLabel>
                    <Select
                      name="Role"
                      value={formData.Role}
                      onChange={handleChange}
                      size="small"
                    >
                      <MenuItem value="App Admin">App Admin</MenuItem>
                      <MenuItem value="Merchant Admin">Merchant Admin</MenuItem>
                      {isLoggedIn?.Role == "System Admin" && <MenuItem value="System Admin">System Admin</MenuItem>}
                    </Select>
                    <Typography color="error">{formErrors.Role}</Typography>
                  </FormControl>
                </Grid>
                {(formData.Role == "Merchant Admin" && !userData) && <Grid item sm={12} xs={12} md={12}>
                  <FormControl fullWidth error={!!formErrors.Merchant}>
                    <InputLabel>Select Merchant</InputLabel>
                    <Select
                      value={merchant}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderColor: formErrors.Merchant ? 'red' : '',
                        },
                      }}
                      size="small"
                      label="Select Merchant"
                      onChange={(e) => {
                        setFormData({ ...formData, "Name": e.target.value });
                        setMerchant(e.target.value)
                      }}
                    >
                      {merchantData.length>0&&merchantData.map((m) =>
                        <MenuItem key={m.Name} value={m.Name}>{m.Name}</MenuItem>
                      )}
                    </Select>
                    {/* {validationMessage && (
                    <p style={{ color: "red", marginTop: "5px" }}>{validationMessage}</p>
                  )} */}
                    {!!formErrors.Merchant && <p style={{ color: 'red' }}>{formErrors.Merchant}</p>}
                  </FormControl>
                </Grid>}
                {/* {isLoggedIn?.Role == "System Admin" &&  */}
                <Grid item sm={12} xs={12} md={12}>
                  <FormControl fullWidth required error={!!formErrors.AppId}>
                    <InputLabel>App ID</InputLabel>
                    <Select
                      name="AppId"
                      multiple
                      value={formData.AppId}
                      size="small"
                      onChange={handleChange}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <div key={value}>
                              {appData.find(app => app.AppID === value)?.AppName}
                            </div>
                          ))}
                        </div>
                      )}
                    >
                      {appData.map((app) => (
                        <MenuItem key={app.AppID} value={app.AppID}>
                          {app.AppName}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography color="error">{formErrors.AppId}</Typography>
                  </FormControl>
                </Grid>
                {/* } */}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" variant="contained" color="primary">
                      {userData ? 'Update User' : 'Add User'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUserPage;
