import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid, Button, TextField, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { login } from 'Store/Slices/Authslice';
import { setAppdata } from 'Store/Slices/Appslice';
import './AppSelection.css';
import { useSelector } from "react-redux";
import { setMerchantdata } from 'Store/Slices/Merchantslice';

const AppSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth);
  const logdata = location.state && location.state.data || isLoggedIn;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [appData, setAppData] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);

  const fetchData = async () => {
    try {
      if (logdata.user.Role == "Merchant Admin") {
        const response = await axios.get(
          "https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/merchant",
        );
       
        if (response.data.code === "0") {
          const allappMerchantdata = response.data.merchants;
          const filterdata = allappMerchantdata.filter(item => logdata.user.AppId.includes(item._id));
          setAppData(filterdata);
          setIsModalOpen(true);
        }

      } else {
        const response = await axios.get("https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/app");
        if (response.data.code === "0") {
          const getappdata = response.data.apps;
          if(logdata.Role=="System Admin"){
            setAppData(getappdata);
          }else{
            const filterdata = getappdata.filter(item => logdata.user.AppId.includes(item.AppID));
            setAppData(filterdata);
          }
         
          setIsModalOpen(true);
        }
      }

    } catch (error) {
      console.error("Error fetching app data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseModal = () => { 
    setIsModalOpen(false); 
    if( logdata.user.Role=="Merchant Admin"){
      navigate("/dashboard/order")
    }if(logdata.user.Role=="App Admin"){
      navigate("/dashboard/order")
    }else{
     navigate("/dashboard/order")
    }
 
   };
  const handleSubmit = () => {
    if (logdata) {
      dispatch(login(logdata));
    }
    if(logdata.user.Role=="Merchant Admin"){
      dispatch(setMerchantdata({ "merchantdata": selectedApp }));
      handleCloseModal()
    }else{
      dispatch(setAppdata({ "appdata": selectedApp }));
      handleCloseModal()
    }
  
    // Add submit logic here
  };
  const handleAppClick = (app) => {
    setSelectedApp(app);
  };

  const filteredApps = appData.filter(app =>  app.AppName?app.AppName.toLowerCase().includes(searchTerm.toLowerCase()):app.Name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <>
      <Modal
        open={isModalOpen}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          height: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <div>
            <strong id="modal-title">{logdata.user.Role == "Merchant Admin"?"Select Merchant":"Select App"}</strong>
            {/* <form className="mt-3">
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <TextField
                    fullWidth
                    name="search"
                    label="Search Apps"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Grid>
              </Grid>
            </form> */}
            <List className="app-list">
              {filteredApps.map((app, index) => (
                <ListItem
                  key={index}
                  className={`app-list-item ${selectedApp === app ? 'selected' : ''}`}
                  onClick={() => handleAppClick(app)}
                >
                  <ListItemAvatar>
                    <Avatar src={app.imageUrl} alt={app?.AppName || app?.Name} className="app-avatar" />
                  </ListItemAvatar>
                  <ListItemText primary={app?.AppName || app?.Name} className="app-list-item-text" />
                </ListItem>
              ))}
            </List>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-end mt-3">
              <Button type="submit" variant="contained" onClick={handleSubmit}>
                Done
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AppSelection;
