import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Card, FormLabel, Button, Typography ,CardActions , CardContent } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { H5 } from "components/Typography";
import IconWrapper from "components/IconWrapper";
import AppTextField from "components/input-fields/AppTextField";
import QuillEditor from "page-sections/admin-ecommerce/add-product/QuillEditor";
import axios from "axios";
import toast from "react-hot-toast";
import { convert } from "html-to-text";
import { Title } from "@mui/icons-material";

const NotificationMessages = () => {
  const [notificationData, setNotificationData] = useState(() => {
    const storedNotifications = localStorage.getItem("notificationData");
    return storedNotifications ? JSON.parse(storedNotifications) : [];
  });
  let appId;
  useEffect(() => {
    // Update localStorage whenever notificationData changes
    localStorage.setItem("notificationData", JSON.stringify(notificationData));
  }, [notificationData]);
  const initialValues = {
    Title: "",
    ActionURL: "",
    IconURL: "",
    Body: "",
    Data: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChangeDescription1 = (value) => {
    setFormData({ ...formData, Body: value });
  };

  const handleChangeDescription2 = (value) => {
    setFormData({ ...formData, Data: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // navigate("/dashboard/notification-token-list", {
    //   state: { Data: formData, appID: appId },
    // });
    const options = {
      wordwrap: 130,
    };
    const htmlBody = formData.Body;
    const textBody = convert(htmlBody, options);
    const newNotifiication = {
      Title: formData.Title,
      Body: textBody,
      Data: formData.Data,
      ActionURL: formData.ActionURL,
      IconURL: formData.IconURL,
    };
    setNotificationData([...notificationData, newNotifiication]);
    setFormData(initialValues);
    localStorage.setItem("notificationData", JSON.stringify([...notificationData, newNotifiication]));
   // localStorage.setItem("notificationData", JSON.stringify(notificationData));
   navigate("/dashboard/notification-list" )
  };
  const handleSend = (item) => {
    navigate("/dashboard/notification-token-list", {
      state: { Data: item, appID: appId },
    });
  };
  const handleRemoveNotification = (index) => {
    const updatedNotifications = [...notificationData];
    updatedNotifications.splice(index, 1);
    setNotificationData(updatedNotifications);
  };
  const handleSendToAll = async (Data) => {
    const response = await axios.get(
      `https://omniposibleapi-bz4mvd3zea-el.a.run.app/admin/api/v1/notification/${appId}`
    );
    const allHomeNotificationdata = response.data.notfications; // Corrected spelling

    if (!response.data.notfications || !Array.isArray(response.data.notfications)) {
        throw new Error("Invalid or empty notifications data");
      }
    const rows = allHomeNotificationdata.map((item) => ({
      id: item._id,
      NotificationToken: item.NotificationToken,
      CustomerID: item.CustomerID,
      Source: item.Source,
    }));
    const allTokens = rows.map((row) => row.NotificationToken);

    try {
      const url = `http://localhost:5001/admin/api/v1/notification`;
      const options = {
        wordwrap: 130,
      };
      const htmlBody = Data.Body;
      const textBody = convert(htmlBody, options);

      const requestData = {
        MessageToken: allTokens,
        Title: Data.Title,
        Body: textBody,
        ...(Data.IconURL && { ImgUrl: Data.IconURL }),
      };
      console.log("requestData", requestData);
      const response = await axios.post(url, requestData);
      toast.success("Notification Sent Successfully" , response);
    } catch (error) {
      console.error("Error Sending Notification:", error);
    }
  };

  return (
    <Box pt={2} pb={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <IconWrapper>
              <NotificationsActiveIcon sx={{ color: "primary.main" }} />
            </IconWrapper>
            <H5>Send Notifications</H5>
          </Box>
        </Grid>

        <Grid item md={7} xs={12}>
          <Card sx={{ padding: 3 }}>
            <H5 mb={3}>General Details</H5>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <AppTextField
                    label="Title"
                    fullWidth
                    size="small"
                    name="Title"
                    value={formData.Title}
                    onChange={(e) =>
                      setFormData({ ...formData, Title: e.target.value })
                    }
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormLabel sx={{ marginBottom: "15px" }}>Body</FormLabel>
                  <QuillEditor
                    value={formData.Body}
                    onChange={handleChangeDescription1}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <AppTextField
                    label="Click Action URL - (optional)"
                    fullWidth
                    size="small"
                    name="ActionURL"
                    value={formData.ActionURL}
                    onChange={(e) =>
                      setFormData({ ...formData, ActionURL: e.target.value })
                    }
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <AppTextField
                    label="Icon URL - (optional)"
                    fullWidth
                    size="small"
                    name="IconURL"
                    value={formData.IconURL}
                    onChange={(e) =>
                      setFormData({ ...formData, IconURL: e.target.value })
                    }
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormLabel sx={{ marginBottom: "15px" }}>
                    Data - (optional)
                  </FormLabel>
                  <QuillEditor
                    value={formData.Data}
                    onChange={handleChangeDescription2}
                  />
                </Grid>
              </Grid>
              <Box display="flex" gap={2} mt={3}>
                {/* <Button variant="contained" type="submit">
                  Send
                </Button>
                <Button variant="contained" onClick={handleSendToAll}>
                  Send To All
                </Button>
                <Button variant="outlined" onClick={() => navigate("/")}>
                  Cancel
                </Button> */}
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Box>
            </form>
          </Card>
        </Grid>

        {/* Notification Cards */}
        {/* <Grid item xs={12}>
          <Grid container spacing={3}>
            {notificationData.map((item, index) => (
              <Grid item md={4} xs={12} key={index}>
                <Card sx={{ padding: 3 }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      Title :- {item.Title}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                     Body:-  {item.Body}
                    </Typography>
                    <Typography variant="body2">
                    Image: - {item.IconURL}
                    </Typography>
                  </CardContent>
                  <CardActions>
                  <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleSend(item)}
                    >
                      Send
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleSendToAll(item)}
                    >
                      Send To All
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleRemoveNotification(index)}
                    >
                      Remove
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default NotificationMessages;
