import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import FlexBetween from "components/flexbox/FlexBetween";
import { H5 } from "components/Typography";
import { StyledPagination } from "page-sections/data-table/dataTableV2/styledComponents";
import { useEffect, useMemo } from "react";
import { useExpanded, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import ScrollBar from "simplebar-react"; // component props interface
// import './CustomTable.css'
const CustomTable = props => {
  const {
    data,
    rowClick,
    showFooter,
    columnShape,
    hidePagination,
    pageSize
  } = props; // hooks

  const tableData = useMemo(() => data, [data]);
  const columns = useMemo(() => columnShape, [columnShape]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    setPageSize // Get setPageSize from useTable
  } = useTable({
    columns,
    data: tableData,
    initialState: { pageSize: pageSize || 20 }
  }, useSortBy, useExpanded, usePagination, useRowSelect); // handle pagination

  const handleChange = (_e, currentPageNo) => {
    gotoPage(currentPageNo - 1);
  };
  // Optionally update pageSize if the prop changes
  useEffect(() => {
    if (pageSize) {
      setPageSize(pageSize);
    }
  }, [pageSize, setPageSize]);

  return <Box>
      <ScrollBar>
        <Table {...getTableProps()} sx={{
        borderSpacing: "0 1rem",
        borderCollapse: "separate"
      }}>
          <TableHead>
            {headerGroups.map(headerGroup => <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} sx={{
              paddingY: 0,
              fontSize: 12,
              fontWeight: 600,
              borderBottom: 0,
              backgroundColor: "rgb(243, 244, 246)" ,
              // width: column.width,
              color: "text.secondary",
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              "&:last-child": {
                textAlign: "center"
                
              },
              "&:first-of-type": {
                paddingLeft: "15px",
                padding:" 20px"
              },
              "&:last-of-type": {
                paddingLeft: "15px",
                padding: "20px"
              }
            }}>
                    {column.render("Header")}
                  </TableCell>)}
              </TableRow>)}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map(row => {
            prepareRow(row);
            return <TableRow {...row.getRowProps()} onClick={rowClick && rowClick(row.original)} sx={{
              backgroundColor: "background.paper",
              cursor: rowClick ? "pointer" : "unset",
              "& td:first-of-type": {
                borderLeft: "0",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                // paddingLeft: "15px"
              },
              "& td:last-of-type": {
                borderRight: "0",
                textAlign: "center",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                paddingLeft: "15px"
              }
            }}>
                  {row.cells.map(cell => {
                return <TableCell {...cell.getCellProps()} sx={{
                  border: 0,
                  fontSize: 13,
                  fontWeight: 500,
                  borderBottom: "1px solid #D3E6F3",
                  color: "text.secondary",
                  "&:first-child": {
                    paddingLeft: "16px"
                  },
                  "&:last-child": {
                    paddingRight: "16px"
                  }
                }}>
                        {cell.render("Cell")}
                      </TableCell>;
              })}
                </TableRow>;
          })}
          </TableBody>
        </Table>
      </ScrollBar>

      {/* {!hidePagination && <Stack alignItems="flex-end" marginY={1}>
          <StyledPagination count={pageOptions.length} shape="rounded" onChange={handleChange} />
        </Stack>} */}

      {showFooter && <FlexBetween gap={0.5}>
          <H5 color="text.secondary" fontSize={13}>
            Showing 1-12 of 24 result
          </H5>
          <ButtonBase disableRipple sx={{
        fontSize: 13,
        fontWeight: 600,
        color: "text.secondary"
      }}>
            See All
            <ArrowRightAlt />
          </ButtonBase>
        </FlexBetween>}
    </Box>;
};

export default CustomTable;